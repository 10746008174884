'use strict'

# UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and not ltIE6
  IE8 = IE and ltIE8 and not ltIE7 and not ltIE6
  IE9 = IE and ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  IE10 = IE and not ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  Webkit = not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

# URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  # workRegex = /^.*\/pc\/[^\/]+\/.*$/
  workRegex = /^.*theclinic-system\.com.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 2

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

window.onpageshow = (evt) ->
  if evt.persisted
    location.reload()
  return

event =
  all: 'mousedown touchstart pointerdown mouseenter mouseleave'
  type: ''
  start: ''
  enter: ''
  leave: ''
  move: ''
  end: ''
# イベント判定

event.check = (e) ->
  _default = e.type
  event.start = _default
  event.move = if _default = 'pointerdown' then 'pointermove' else if _default = 'touchstart' then 'touchmove' else 'mousemove'
  event.end = if _default = 'pointerdown' then 'pointerup' else if _default = 'touchstart' then 'touchend' else 'click'
  event.enter = if _default = 'pointerenter' then _default else if _default = 'touchenter' then _default else 'mouseenter'
  event.leave = if _default = 'pointerleave' then _default else if _default = 'touchleave' then _default else 'mouseleave'
  return

event.on = (type, elm, fn, delegate) ->
  $(document).off(event.all, elm).on event.all, elm, (e) ->
    e.preventDefault()
    event.check e
    if fn isnt null
      if e.type = event.enter or e.type = event.leave
        # mouseenter mouseleaveのとき、コールバック関数を即実行
        fn this, e
      else
        if type = 'bind'
          # jQuery $(element).on(event, func);
          $(elm).off(event.end).on event.end, (e) ->
            fn this, e
            return
        else if type = 'live'
          # jQuery $(document).on(event, element, func);
          $(document).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
        else if type = 'delegate'
          # jQuery $(parentsElement).on(event, element, func);
          $(delegate).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
    else
      # 引数にコールバック関数が定義されていないときの処理
    return
  return


$(()->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  if $class.indexOf('mail') isnt 0
    firstDownFlg = true
    firstUpFlg = false
    $('.headroom').headroom
      tolerance:
        up:3
        down:10
      offset:0
      classes:
        initial: 'animated'
        pinned: 'slideDown'
        unpinned: 'slideUp'
      onPin: ->
        firstUpFlg = true
        if not firstDownFlg
          return $('.headroom').removeClass('slideUpFirst')
        return
      onUnpin: ->
      onTop: ->
        firstDownFlg = true
        $('.headroom').removeClass 'slideDownFirst slideDown pin outreturn up'
      onNotTop: ->
        if firstDownFlg
          $('.headroom').addClass 'slideUpFirst'
          return firstDownFlg = false
    $(window).scroll ->
      if firstUpFlg and $(this).scrollTop() <= $('.headroom').height()
      else if  firstUpFlg
        $('.headroom').addClass 'outreturn'
        return firstUpFlg = false
      if $(this).scrollTop() <= $('.headroom').height()+40
        $('.headroom').addClass 'pin'
      else $('.headroom').removeClass 'pin'
      if $(this).scrollTop() >= $('.headroom').height()
        $('.headroom.slideDown').addClass 'up'

    scrollTop = undefined
    $('.menu-trigger').bind 'touchend', ->
      scrollTop = $(window).scrollTop()
      $(this).toggleClass 'active'
      $('body').toggleClass 'hidden'
      $('#container').toggleClass('noscroll').css 'top', -scrollTop + 'px'
      $('#header').toggleClass 'stop'
      $('#g-navi').fadeToggle 400

    $('.contact-trigger').bind 'touchend', ->
      scrollTop = $(window).scrollTop()
      $(this).toggleClass 'active'
      $('body').toggleClass 'hidden'
      $('#container').toggleClass('noscroll').css 'top', -scrollTop + 'px'
      $('#header').toggleClass 'stop'
      $('#g-contact').fadeToggle 400

    $('.navi-title').click ->
      if $('+.navi-detail', this).css('display') is 'none'
        $(this).addClass 'is-open'
        $('+.navi-detail', this).slideDown 'normal'
      else
        $(this).removeClass 'is-open'
        $('+.navi-detail', this).slideUp 'normal'
      return

  fixContact = $('.fix-navi-contact')
  topBtn = $('.r-pagetop')
  #最初はボタンを隠す
  fixContact.hide()
  # topBtn.hide()
  #スクロールが300に達したらボタンを表示させる
  $(window).scroll ->
    if $(this).scrollTop() > 300
      fixContact.fadeIn()
      # topBtn.fadeIn()
    else
      fixContact.fadeOut()
      # topBtn.fadeOut()
    return
  topBtn.click ->
    $('body,html').animate
      scrollTop: 0
    , 500
    false

  offsetY = -70
  time = 500
  $('.m-anchor-item a[href^="#"]').on 'click', ->
    target = $(@hash)
    if !target.length
      return
    targetY = target.offset().top + offsetY
    # スクロールアニメーション
    $('html,body').animate { scrollTop: targetY }, time, 'swing'
    window.history.pushState null, null, @hash
    false

  $('.m-case-slide-list').slick
    autoplay: true
    autoplaySpeed: 4000
    speed: 1000
    dots: true
  
  $('.m-tab-item a[href^="#tab-contents"]').click ->
    $('.m-tab-contents-item').hide()
    $(this.hash).fadeIn(400)
    $('.m-tab-item a').parent().removeClass 'is-active'
    $(this).parent().toggleClass 'is-active'
    false
  
)
